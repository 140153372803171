import React from 'react';
import '../../styles/BoutiqueDetail.css';
import ImgMug from '../../images/Mug3.png';

import ImgAccueilBoutique from '../../components/ImgAccueilBoutique';
import Footer from '../../components/Footer';

const MugsDetail = () => {
    return (
        <div className='BtqDetail'>
            <ImgAccueilBoutique />
            <h1 className='BtqDetail H1BtqDetail'> </h1>
            <h1 className='BtqDetail H2espBtqDetail'> </h1>
            <h2 className='BtqDetail H2BtqDetail'> Mug</h2>
            <h1 className='BtqDetail H2espBtqDetail'> </h1>
            
            <div className='BtqDetail ColonBtqDetail'>
                <img className='ImagBtqMag' src={ImgMug} alt="Gare de Troo" />
                <h3 className='Mag'> </h3>
                <h3 className='H4BtqDetail'>Mug créé en collaboration avec
                <a className='LnkBtq' href="https://www.esat-apf-jeanmuriel.fr/" target="_blank"> L'Esat Jean Muriel de Lunay</a>
                </h3>
                <h3 className='Mag'> </h3>
            </div>
        
            <Footer />     
            
            
        </div>
    );
};

export default MugsDetail;