import React from 'react';
import '../../styles/BoutiqueDetail.css';
import Teeshirt1 from '../../images/Teeshirt1.jpg';

import ImgAccueilBoutique from '../../components/ImgAccueilBoutique';
import Footer from '../../components/Footer';

const TeeshirtsDetail = () => {
    return (
        <div className='BtqDetail'>
            <ImgAccueilBoutique />
            <h1 className='BtqDetail H1BtqDetail'> </h1>
            <h1 className='BtqDetail H2espBtqDetail'> </h1>
            <h2 className='BtqDetail H2BtqDetail'> Tee shirt TTVL</h2>
            <h1 className='BtqDetail H2espBtqDetail'> </h1>
            
            <div className='BtqDetail ColonBtqDetail'>
            <img className='BtqDetail ColonBtqDetail ImagBtqTS' src={Teeshirt1} alt="Gare de Troo" />
                
            </div>
        
            <Footer />    
            
            
        </div>
    );
};

export default TeeshirtsDetail;