import React from 'react';
import '../styles/Actualites.css';
import FormIns from "../imagesActu/FormInscrip2.jpg";
import ReactToPrint from "react-to-print";



class ComponentToPrint extends React.Component {
  
  render() {
    return (
      <div className="bottom">
          <img className='formins' src={FormIns} alt="Formulaire Inscription" />
        
      </div>
    );
  }
}

class ImpFormIns extends React.Component {
  
  render() {
    return (
      <div>
        
        <ReactToPrint
          trigger={() => <a className='ActuH2b' href="#">Cliquer ICI pour imprimer le formulaire d'inscription</a>}
          content={() => this.componentRef}
          
        />
        <div style={{ display: "none" }}><ComponentToPrint ref={el => (this.componentRef = el)} /></div>
        {/* <ComponentToPrint ref={el => (this.componentRef = el)} /> */}
      </div>
    );
  }
}

export default ImpFormIns;

