import React from 'react';
import '../../styles/Horaires.css';
import { useNavigate } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import EntetePageHoraires from '../../components/EntetePageHoraires';

import Footer from '../../components/Footer';

function Septembre () {

    const navigate = useNavigate();

    return (
        <div className='AppAgt'>
        <div>
        <EntetePageHoraires />
        <h2 className='LigneH2t' > - </h2>
        <div >
        <table className='HoTbl' align='center'>
            <tbody>
                <tr>
                    <td className='HoTbllit'><Button className='BtnHor' onClick={() => navigate('/Avril')}>Avril</Button> </td>
                    <td className='HoTbllit'> </td>
                    <td className='HoTbllit'> </td>
                </tr>
                <tr className='HoTblli'>
                    <td className='HoTblli'><Button className='BtnHor' onClick={() => navigate('/Mai')}>Mai</Button> </td>
                    <td className='HoTbllit'> </td>
                    <td className='HoTbllit'> </td>                 
                </tr>
                <tr>
                    <td className='HoTbllit'><Button className='BtnHor' onClick={() => navigate('/Juin')}>Juin</Button> </td>
                    <td className='HoTbllit'>  </td>
                    <td className='HoTbllit'>  </td>
                </tr>
                <tr>
                    <td className='HoTbllit'><Button className='BtnHor' onClick={() => navigate('/Juillet')}>Juillet</Button> </td>
                    <td className='HoTbllit'>  </td>
                    <td className='HoTbllit'>  </td>   
                </tr>
                <tr>
                    <td className='HoTbllit'><Button className='BtnHor' onClick={() => navigate('/Aout')}>Aout</Button> </td>
                    <td className='HoTbllit'> </td>
                    <td className='HoTbllit'> </td>
                </tr>
                <tr>
                    <td className='HoTbllit'><Button className='BtnHor' onClick={() => navigate('/Septembre')}>Septembre</Button> </td>
                    <td className='HoTbllit'> Date </td>
                    <td className='HoTbllit'> Ouverture </td>
                </tr>
                <tr>
                    <td className='HoTblli'>  </td>
                    <td className='HoTblli'> Dim 01/09 </td>
                    <td className='HoTblli'> 10h-12h </td>                 
                </tr>
                <tr className='HoTblli'>
                    <td className='HoTblli'> </td>
                    <td className='HoTblli'> Lun 02/09 </td>
                    <td className='HoTblli'> Fermé </td>                 
                </tr>
                <tr>
                    <td className='HoTblli'>  </td>
                    <td className='HoTblli'> Mar 03/09 </td>
                    <td className='HoTblli'> Fermé </td>                 
                </tr>
                <tr>
                    <td className='HoTblli'>  </td>
                    <td className='HoTblli'> Mer 04/09 </td>
                    <td className='HoTblli'> 14h30-17h </td>                 
                </tr>
                <tr>
                    <td className='HoTblli'>  </td>
                    <td className='HoTblli'> Jeu 05/09 </td>
                    <td className='HoTblli'> 10h-12h </td>                 
                </tr>
                <tr>
                    <td className='HoTblli'>  </td>
                    <td className='HoTblli'> Ven 06/09 </td>
                    <td className='HoTblli'> Fermé </td>                 
                </tr>
                <tr>
                    <td className='HoTblli'>  </td>
                    <td className='HoTblli'> Sam 07/09 </td>
                    <td className='HoTblli'> Fermé </td>                 
                </tr>
                <tr>
                    <td className='HoTblli'>  </td>
                    <td className='HoTblli'> Dim 08/09 </td>
                    <td className='HoTblli'> Fermé </td>                 
                </tr>
                <tr className='HoTblli'>
                    <td className='HoTblli'> </td>
                    <td className='HoTblli'> Lun 09/09 </td>
                    <td className='HoTblli'> Fermé </td>                 
                </tr>
                <tr>
                    <td className='HoTblli'>  </td>
                    <td className='HoTblli'> Mar 10/09 </td>
                    <td className='HoTblli'> 10h-12h </td>                 
                </tr>
                <tr>
                    <td className='HoTblli'>  </td>
                    <td className='HoTblli'> Mer 11/09 </td>
                    <td className='HoTblli'> 14h30-17h </td>                 
                </tr>
                <tr>
                    <td className='HoTblli'>  </td>
                    <td className='HoTblli'> Jeu 12/09 </td>
                    <td className='HoTblli'> Fermé </td>                 
                </tr>
                <tr>
                    <td className='HoTblli'>  </td>
                    <td className='HoTblli'> Ven 13/09 </td>
                    <td className='HoTblli'> Fermé </td>                 
                </tr>
                <tr>
                    <td className='HoTblli'>  </td>
                    <td className='HoTblli'> Sam 14/09 </td>
                    <td className='HoTblli'> Fermé </td>                 
                </tr>
                <tr>
                    <td className='HoTblli'>  </td>
                    <td className='HoTblli'> Dim 15/09 </td>
                    <td className='HoTblli'> Fermé </td>                 
                </tr>
                <tr className='HoTblli'>
                    <td className='HoTblli'> </td>
                    <td className='HoTblli'> Lun 16/09 </td>
                    <td className='HoTblli'> Fermé </td>                 
                </tr>
                <tr>
                    <td className='HoTblli'>  </td>
                    <td className='HoTblli'> Mar 17/09 </td>
                    <td className='HoTblli'> Fermé </td>                 
                </tr>
                <tr>
                    <td className='HoTblli'>  </td>
                    <td className='HoTblli'> Mer 18/09 </td>
                    <td className='HoTblli'> 14h30-17h </td>                 
                </tr>
                <tr>
                    <td className='HoTblli'>  </td>
                    <td className='HoTblli'> Jeu 19/09 </td>
                    <td className='HoTblli'> 10h-12h </td>                 
                </tr>
                <tr>
                    <td className='HoTblli'>  </td>
                    <td className='HoTblli'> Ven 20/09 </td>
                    <td className='HoTblli'> Fermé </td>                 
                </tr>
                <tr>
                    <td className='HoTblli'>  </td>
                    <td className='HoTblli'> Sam 21/09 </td>
                    <td className='HoTblli'> 10h-17h </td>                 
                </tr>
                <tr>
                    <td className='HoTblli'>  </td>
                    <td className='HoTblli'> Dim 22/09 </td>
                    <td className='HoTblli'> 10h-17h </td>                 
                </tr>
                <tr className='HoTblli'>
                    <td className='HoTblli'> </td>
                    <td className='HoTblli'> Lun 23/09 </td>
                    <td className='HoTblli'> Fermé </td>                 
                </tr>
                <tr>
                    <td className='HoTblli'>  </td>
                    <td className='HoTblli'> Mar 24/09 </td>
                    <td className='HoTblli'> Fermé </td>                 
                </tr>
                <tr>
                    <td className='HoTblli'>  </td>
                    <td className='HoTblli'> Mer 25/09 </td>
                    <td className='HoTblli'> 14h30-17h </td>                 
                </tr>
                <tr>
                    <td className='HoTblli'>  </td>
                    <td className='HoTblli'> Jeu 26/09 </td>
                    <td className='HoTblli'> Fermé </td>                 
                </tr>
                <tr>
                    <td className='HoTblli'>  </td>
                    <td className='HoTblli'> Ven 27/09 </td>
                    <td className='HoTblli'> Fermé </td>                 
                </tr>
                <tr>
                    <td className='HoTblli'>  </td>
                    <td className='HoTblli'> Sam 28/09 </td>
                    <td className='HoTblli'> Fermé </td>                 
                </tr>
                <tr>
                    <td className='HoTblli'>  </td>
                    <td className='HoTblli'> Dim 29/09 </td>
                    <td className='HoTblli'> Fermé </td>                 
                </tr>
                <tr className='HoTblli'>
                    <td className='HoTblli'> </td>
                    <td className='HoTblli'> Lun 30/09 </td>
                    <td className='HoTblli'> Fermé </td>                 
                </tr>
                <tr>
                    <td className='HoTbllit'><Button className='BtnHor' onClick={() => navigate('/Octobre')}>Octobre</Button> </td>
                    <td className='HoTbllit'> </td>
                    <td className='HoTbllit'> </td>
                </tr>
                <tr>
                    <td className='HoTbllit'><Button className='BtnHor' onClick={() => navigate('/Novembre')}>Novembre</Button> </td>
                    <td className='HoTbllit'> </td>
                    <td className='HoTbllit'> </td>
                </tr>
                
             </tbody>
            </table> 
            <h2 className='LigneH2t' > - </h2>  
            </div>    
            <Footer />
            
        </div>
    </div>
    );
};

export default Septembre;