import React from 'react';

import '../styles/Boutique.css';

import ImgAccueilBoutique from '../components/ImgAccueilBoutique';

import Footer from '../components/Footer';

const Boutique = () => {
    return (
        <div className='AppAgt'>
            
            <div className='Boutique'>
                
                <ImgAccueilBoutique /> 
                
            </div>
            <Footer />
        </div>
    );
};

export default Boutique;