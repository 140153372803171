import React from 'react';
import '../../styles/Horaires.css';
import { useNavigate } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import EntetePageHoraires from '../../components/EntetePageHoraires';

import Footer from '../../components/Footer';

function Octobre () {

    const navigate = useNavigate();

    return (
        <div className='AppAgt'>
        <div>
        <EntetePageHoraires />
        <h2 className='LigneH2t' > - </h2>
        <div >
        <table className='HoTbl' align='center'>
            <tbody>
                <tr>
                    <td className='HoTbllit'><Button className='BtnHor' onClick={() => navigate('/Avril')}>Avril</Button> </td>
                    <td className='HoTbllit'> </td>
                    <td className='HoTbllit'> </td>
                </tr>
                <tr className='HoTblli'>
                    <td className='HoTblli'><Button className='BtnHor' onClick={() => navigate('/Mai')}>Mai</Button> </td>
                    <td className='HoTbllit'> </td>
                    <td className='HoTbllit'> </td>                 
                </tr>
                <tr>
                    <td className='HoTbllit'><Button className='BtnHor' onClick={() => navigate('/Juin')}>Juin</Button> </td>
                    <td className='HoTbllit'>  </td>
                    <td className='HoTbllit'>  </td>
                </tr>
                <tr>
                    <td className='HoTbllit'><Button className='BtnHor' onClick={() => navigate('/Juillet')}>Juillet</Button> </td>
                    <td className='HoTbllit'>  </td>
                    <td className='HoTbllit'>  </td>   
                </tr>
                <tr>
                    <td className='HoTbllit'><Button className='BtnHor' onClick={() => navigate('/Aout')}>Aout</Button> </td>
                    <td className='HoTbllit'> </td>
                    <td className='HoTbllit'> </td>
                </tr>
                <tr>
                    <td className='HoTbllit'><Button className='BtnHor' onClick={() => navigate('/Septembre')}>Septembre</Button> </td>
                    <td className='HoTbllit'> </td>
                    <td className='HoTbllit'> </td>
                </tr>
                <tr>
                    <td className='HoTbllit'><Button className='BtnHor' onClick={() => navigate('/Octobre')}>Octobre</Button> </td>
                    <td className='HoTbllit'> </td>
                    <td className='HoTbllit'> </td>
                </tr>
                <tr>
                    <td className='HoTbllit'><Button className='BtnHor' onClick={() => navigate('/Novembre')}>Novembre</Button> </td>
                    <td className='HoTbllit'> Date </td>
                    <td className='HoTbllit'> Ouverture </td>
                </tr>
                
                <tr>
                    <td className='HoTblli'>  </td>
                    <td className='HoTblli'> Ven 01/11 </td>
                    <td className='HoTblli'> Fermé </td>                 
                </tr>
                <tr>
                    <td className='HoTblli'>  </td>
                    <td className='HoTblli'> Sam 02/11 </td>
                    <td className='HoTblli'> Fermé </td>                 
                </tr>
                <tr>
                    <td className='HoTblli'>  </td>
                    <td className='HoTblli'> Dim 03/11 </td>
                    <td className='HoTblli'> Fermé </td>                 
                </tr>
                <tr className='HoTblli'>
                    <td className='HoTblli'> </td>
                    <td className='HoTblli'> Lun 04/11 </td>
                    <td className='HoTblli'> Fermé </td>                 
                </tr>
                <tr>
                    <td className='HoTblli'>  </td>
                    <td className='HoTblli'> Mar 05/11 </td>
                    <td className='HoTblli'> Fermé </td>                 
                </tr>
                <tr>
                    <td className='HoTblli'>  </td>
                    <td className='HoTblli'> Mer 06/11 </td>
                    <td className='HoTblli'> 14h30-17h </td>                 
                </tr>
                <tr>
                    <td className='HoTblli'>  </td>
                    <td className='HoTblli'> Jeu 07/11 </td>
                    <td className='HoTblli'> Fermé </td>                 
                </tr>
                <tr>
                    <td className='HoTblli'>  </td>
                    <td className='HoTblli'> Ven 08/11 </td>
                    <td className='HoTblli'> Fermé </td>                 
                </tr>
                <tr>
                    <td className='HoTblli'>  </td>
                    <td className='HoTblli'> Sam 09/11 </td>
                    <td className='HoTblli'> Fermé </td>                 
                </tr>
                <tr>
                    <td className='HoTblli'>  </td>
                    <td className='HoTblli'> Dim 10/11 </td>
                    <td className='HoTblli'> Fermé </td>                 
                </tr>
                <tr className='HoTblli'>
                    <td className='HoTblli'> </td>
                    <td className='HoTblli'> Lun 11/11 </td>
                    <td className='HoTblli'> Fermé </td>                 
                </tr>
                <tr>
                    <td className='HoTblli'>  </td>
                    <td className='HoTblli'> Mar 12/11 </td>
                    <td className='HoTblli'> Fermé </td>                 
                </tr>
                <tr>
                    <td className='HoTblli'>  </td>
                    <td className='HoTblli'> Mer 13/11 </td>
                    <td className='HoTblli'> 14h30-17h </td>                 
                </tr>
                <tr>
                    <td className='HoTblli'>  </td>
                    <td className='HoTblli'> Jeu 14/11 </td>
                    <td className='HoTblli'> Fermé </td>                 
                </tr>
                <tr>
                    <td className='HoTblli'>  </td>
                    <td className='HoTblli'> Ven 15/11 </td>
                    <td className='HoTblli'> Fermé </td>                 
                </tr>
                <tr>
                    <td className='HoTblli'>  </td>
                    <td className='HoTblli'> Sam 16/11 </td>
                    <td className='HoTblli'> Fermé </td>                 
                </tr>
                <tr>
                    <td className='HoTblli'>  </td>
                    <td className='HoTblli'> Dim 17/11 </td>
                    <td className='HoTblli'> Fermé </td>                 
                </tr>
                <tr className='HoTblli'>
                    <td className='HoTblli'> </td>
                    <td className='HoTblli'> Lun 18/11 </td>
                    <td className='HoTblli'> Fermé </td>                 
                </tr>
                <tr>
                    <td className='HoTblli'>  </td>
                    <td className='HoTblli'> Mar 19/11 </td>
                    <td className='HoTblli'> Fermé </td>                 
                </tr>
                <tr>
                    <td className='HoTblli'>  </td>
                    <td className='HoTblli'> Mer 20/11 </td>
                    <td className='HoTblli'> 14h30-17h </td>                 
                </tr>
                <tr>
                    <td className='HoTblli'>  </td>
                    <td className='HoTblli'> Jeu 21/11 </td>
                    <td className='HoTblli'> Fermé </td>                 
                </tr>
                <tr>
                    <td className='HoTblli'>  </td>
                    <td className='HoTblli'> Ven 22/11 </td>
                    <td className='HoTblli'> Fermé </td>                 
                </tr>
                <tr>
                    <td className='HoTblli'>  </td>
                    <td className='HoTblli'> Sam 23/11 </td>
                    <td className='HoTblli'> Fermé </td>                 
                </tr>
                <tr>
                    <td className='HoTblli'>  </td>
                    <td className='HoTblli'> Dim 24/11 </td>
                    <td className='HoTblli'> Fermé </td>                 
                </tr>
                <tr className='HoTblli'>
                    <td className='HoTblli'> </td>
                    <td className='HoTblli'> Lun 25/11 </td>
                    <td className='HoTblli'> Fermé </td>                 
                </tr>
                <tr>
                    <td className='HoTblli'>  </td>
                    <td className='HoTblli'> Mar 26/11 </td>
                    <td className='HoTblli'> Fermé </td>                 
                </tr>
                <tr>
                    <td className='HoTblli'>  </td>
                    <td className='HoTblli'> Mer 27/11 </td>
                    <td className='HoTblli'> 14h30-17h </td>                 
                </tr>
                <tr>
                    <td className='HoTblli'>  </td>
                    <td className='HoTblli'> Jeu 28/11 </td>
                    <td className='HoTblli'> Fermé </td>                 
                </tr>
                <tr>
                    <td className='HoTblli'>  </td>
                    <td className='HoTblli'> Ven 29/11 </td>
                    <td className='HoTblli'> Fermé </td>                 
                </tr>
                <tr>
                    <td className='HoTblli'>  </td>
                    <td className='HoTblli'> Sam 30/11 </td>
                    <td className='HoTblli'> Fermé </td>                 
                </tr>
                
             </tbody>
            </table> 
            <h2 className='LigneH2t' > - </h2>  
            </div>    
            <Footer />
            
        </div>
    </div>
    );
};

export default Octobre;