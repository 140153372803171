import React from 'react';
import '../../styles/Presentation.css';
import ImgGareTrooEt11 from '../../images/Petage1.jpg';
import RoseRonsard from '../../images/RoseRonsard.jpg';

const Petage = () => {
    return (
        <div className='Gare'>
            <div className='LigneTitre'>
                <h1 className='LigneTitre'> Premier Etage</h1>
            </div>
            
            <div className='GareLigne ColonPres'>
                <img className='ImagPres' src={ImgGareTrooEt11} alt="Ronsard" />
                <h2 className='GareH2'>Au premier étage une balade poétique en vallée du Loir avec des vidéos, photos, projections, bruitage.... 
                Vous y découvrirez le Loir, ses châteaux, les troglos, les fresques et Ronsard.
                </h2>
                <img className='ImagPres' src={RoseRonsard} alt="Rose Ronsard" /> 
            </div>
        </div>
    );
};

export default Petage;