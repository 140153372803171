import React, { Component } from 'react';
import Button from 'react-bootstrap/Button';
import 'bootstrap/dist/css/bootstrap.min.css';

class Redirection extends Component {
    RedirectionTTVL() {
        window.open('https://www.ttvl.fr');
        
    }
    render() {
    return (
        <div>
        <Button variant="primary" className="BtnAcc" onClick={this.RedirectionTTVL}  >
            Pour tout renseignement sur les circulations TTVL Voir le site officiel TTVL : www.ttvl.fr
        </Button>
        </div>
    );
    }

};

export default Redirection;