import React from 'react';
import '../styles/Contact.css';
import Navigation from '../components/Navigation';
import ImgContact from '../images/ContactSite2.jpg';
import Footer from '../components/Footer';

const Contact = () => {
    return (
        <div className='AppAgt'>
            <div className='Contact'>
            <h2 className='LigneH2t' > - </h2>
            <img src={ImgContact} className='ImgCtact' alt='Image Contact' />
            
            {/* <h2 className='LigneH2' >Vous pouvez nous contacter par courriel à l'adresse suivante : garedetroo@gmail.com</h2>
            <h2 className='LigneH2' >ou</h2>
            <h2 className='LigneH2' >Vous pouvez nous rendre visite à la gare</h2>
            <h2 className='LigneH2t' > - </h2>
            <h2 className='LigneH2cb' >&rArr; Voir nos horaires dans l'onglet Horaires</h2>
                     
            <h2 className='LigneH2cb' >Tarif de la visite : 1,5 Euro pour les adultes, gratuit pour les enfants de moins de 10 ans accompagnés</h2>
            <h2 className='LigneH2cb' ></h2>
            <h2 className='LigneH2cb' >Buvette à la gare pendant les horaires d'ouverture. Tables de pique-nique disponibles à l'extérieur</h2>
            <h2 className='LigneH2cb' >Et notre boutique....</h2> */}
            <h2 className='LigneH2cb' ></h2>
            <h2 className='LigneH2cb' ></h2>
            <h2 className='LigneH2cb' ></h2>
            <h2 className='LigneH2cb' ></h2>
            <h2 className='LigneH2cb' ></h2>
            <h2 className='LigneH2cb' ></h2>
            <Footer />
            </div>
        </div>
    );
};

export default Contact;