import React from 'react';
import '../../styles/Horaires.css';
import { useNavigate } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import EntetePageHoraires from '../../components/EntetePageHoraires';

import Footer from '../../components/Footer';

function Aout () {

    const navigate = useNavigate();

    return (
        <div className='AppAgt'>
        <div>
        <EntetePageHoraires />
        <h2 className='LigneH2t' > - </h2>
        <div >
        <table className='HoTbl' align='center'>
            <tbody>
                <tr>
                    <td className='HoTbllit'><Button className='BtnHor' onClick={() => navigate('/Avril')}>Avril</Button> </td>
                    <td className='HoTbllit'> </td>
                    <td className='HoTbllit'> </td>
                </tr>
                <tr className='HoTblli'>
                    <td className='HoTblli'><Button className='BtnHor' onClick={() => navigate('/Mai')}>Mai</Button> </td>
                    <td className='HoTbllit'> </td>
                    <td className='HoTbllit'> </td>                 
                </tr>
                <tr>
                    <td className='HoTbllit'><Button className='BtnHor' onClick={() => navigate('/Juin')}>Juin</Button> </td>
                    <td className='HoTbllit'>  </td>
                    <td className='HoTbllit'>  </td>
                </tr>
                <tr>
                    <td className='HoTbllit'><Button className='BtnHor' onClick={() => navigate('/Juillet')}>Juillet</Button> </td>
                    <td className='HoTbllit'>  </td>
                    <td className='HoTbllit'>  </td>   
                </tr>
                <tr>
                    <td className='HoTbllit'><Button className='BtnHor' onClick={() => navigate('/Aout')}>Aout</Button> </td>
                    <td className='HoTbllit'> Date </td>
                    <td className='HoTbllit'> Ouverture </td>
                </tr>
                
                <tr>
                    <td className='HoTblli'>  </td>
                    <td className='HoTblli'> Jeu 01/08 </td>
                    <td className='HoTblli'> 10h-12h </td>                 
                </tr>
                <tr>
                    <td className='HoTblli'>  </td>
                    <td className='HoTblli'> Ven 02/08 </td>
                    <td className='HoTblli'> Fermé </td>                 
                </tr>
                <tr>
                    <td className='HoTblli'>  </td>
                    <td className='HoTblli'> Sam 03/08 </td>
                    <td className='HoTblli'> 10h-12h 14h30-17h </td>                 
                </tr>
                <tr>
                    <td className='HoTblli'>  </td>
                    <td className='HoTblli'> Dim 04/08 </td>
                    <td className='HoTblli'> 14h30-17h </td>                 
                </tr>
                <tr className='HoTblli'>
                    <td className='HoTblli'> </td>
                    <td className='HoTblli'> Lun 05/08 </td>
                    <td className='HoTblli'> Fermé </td>                 
                </tr>
                <tr>
                    <td className='HoTblli'>  </td>
                    <td className='HoTblli'> Mar 06/08 </td>
                    <td className='HoTblli'> Fermé </td>                 
                </tr>
                <tr>
                    <td className='HoTblli'>  </td>
                    <td className='HoTblli'> Mer 07/08 </td>
                    <td className='HoTblli'> 14h30-17h </td>                 
                </tr>
                <tr>
                    <td className='HoTblli'>  </td>
                    <td className='HoTblli'> Jeu 08/08 </td>
                    <td className='HoTblli'> 10h-12h </td>                 
                </tr>
                <tr>
                    <td className='HoTblli'>  </td>
                    <td className='HoTblli'> Ven 09/08 </td>
                    <td className='HoTblli'> Fermé </td>                 
                </tr>
                <tr>
                    <td className='HoTblli'>  </td>
                    <td className='HoTblli'> Sam 10/08 </td>
                    <td className='HoTblli'> 10h-12h 14h30-17h </td>                 
                </tr>
                <tr>
                    <td className='HoTblli'>  </td>
                    <td className='HoTblli'> Dim 11/08 </td>
                    <td className='HoTblli'> 10h-12h 14h30-17h </td>                 
                </tr>
                <tr className='HoTblli'>
                    <td className='HoTblli'> </td>
                    <td className='HoTblli'> Lun 12/08 </td>
                    <td className='HoTblli'> Fermé </td>                 
                </tr>
                <tr>
                    <td className='HoTblli'>  </td>
                    <td className='HoTblli'> Mar 13/08 </td>
                    <td className='HoTblli'> Fermé </td>                 
                </tr>
                <tr>
                    <td className='HoTblli'>  </td>
                    <td className='HoTblli'> Mer 14/08 </td>
                    <td className='HoTblli'> 14h30-17h </td>                 
                </tr>
                <tr>
                    <td className='HoTblli'>  </td>
                    <td className='HoTblli'> Jeu 15/08 </td>
                    <td className='HoTblli'> 10h-12h </td>                 
                </tr>
                <tr>
                    <td className='HoTblli'>  </td>
                    <td className='HoTblli'> Ven 16/08 </td>
                    <td className='HoTblli'> Fermé </td>                 
                </tr>
                <tr>
                    <td className='HoTblli'>  </td>
                    <td className='HoTblli'> Sam 17/08 </td>
                    <td className='HoTblli'> 14h30-17h </td>                 
                </tr>
                <tr>
                    <td className='HoTblli'>  </td>
                    <td className='HoTblli'> Dim 18/08 </td>
                    <td className='HoTblli'> Fermé </td>                 
                </tr>
                <tr className='HoTblli'>
                    <td className='HoTblli'> </td>
                    <td className='HoTblli'> Lun 19/08 </td>
                    <td className='HoTblli'> Fermé </td>                 
                </tr>
                <tr>
                    <td className='HoTblli'>  </td>
                    <td className='HoTblli'> Mar 20/08 </td>
                    <td className='HoTblli'> 10h-12h </td>                 
                </tr>
                <tr>
                    <td className='HoTblli'>  </td>
                    <td className='HoTblli'> Mer 21/08 </td>
                    <td className='HoTblli'> 14h30-17h </td>                 
                </tr>
                <tr>
                    <td className='HoTblli'>  </td>
                    <td className='HoTblli'> Jeu 22/08 </td>
                    <td className='HoTblli'> 10h-12h </td>                 
                </tr>
                <tr>
                    <td className='HoTblli'>  </td>
                    <td className='HoTblli'> Ven 23/08 </td>
                    <td className='HoTblli'> Fermé </td>                 
                </tr>
                <tr>
                    <td className='HoTblli'>  </td>
                    <td className='HoTblli'> Sam 24/08 </td>
                    <td className='HoTblli'> 14h30-17h </td>                 
                </tr>
                <tr>
                    <td className='HoTblli'>  </td>
                    <td className='HoTblli'> Dim 25/08 </td>
                    <td className='HoTblli'> 10h-12h</td>                 
                </tr>
                <tr className='HoTblli'>
                    <td className='HoTblli'> </td>
                    <td className='HoTblli'> Lun 26/08 </td>
                    <td className='HoTblli'> Fermé </td>                 
                </tr>
                <tr>
                    <td className='HoTblli'>  </td>
                    <td className='HoTblli'> Mar 27/08 </td>
                    <td className='HoTblli'> Fermé </td>                 
                </tr>
                <tr>
                    <td className='HoTblli'>  </td>
                    <td className='HoTblli'> Mer 28/08 </td>
                    <td className='HoTblli'> 14h30-17h </td>                 
                </tr>
                <tr>
                    <td className='HoTblli'>  </td>
                    <td className='HoTblli'> Jeu 29/08 </td>
                    <td className='HoTblli'> 10h-12h </td>                 
                </tr>
                <tr>
                    <td className='HoTblli'>  </td>
                    <td className='HoTblli'> Ven 30/08 </td>
                    <td className='HoTblli'> Fermé </td>                 
                </tr>
                <tr>
                    <td className='HoTblli'>  </td>
                    <td className='HoTblli'> Sam 31/08 </td>
                    <td className='HoTblli'> 10h-12h 14h30-17h </td>                 
                </tr>
                <tr>
                    <td className='HoTbllit'><Button className='BtnHor' onClick={() => navigate('/Septembre')}>Septembre</Button> </td>
                    <td className='HoTbllit'> </td>
                    <td className='HoTbllit'> </td>
                </tr>
                <tr>
                    <td className='HoTbllit'><Button className='BtnHor' onClick={() => navigate('/Octobre')}>Octobre</Button> </td>
                    <td className='HoTbllit'> </td>
                    <td className='HoTbllit'> </td>
                </tr>
                <tr>
                    <td className='HoTbllit'><Button className='BtnHor' onClick={() => navigate('/Novembre')}>Novembre</Button> </td>
                    <td className='HoTbllit'> </td>
                    <td className='HoTbllit'> </td>
                </tr>
                
             </tbody>
            </table> 
            <h2 className='LigneH2t' > - </h2>  
            </div>    
            <Footer />
            
        </div>
    </div>
    );
};

export default Aout;