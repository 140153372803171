import React from 'react';
import '../styles/Partenaires.css';
import 'react-slideshow-image/dist/styles.css';
import Footer from '../components/Footer';
import logohistorius from '../images/logoHistorius.jpg';
import logoadn41 from '../images/Adn41.jpg';
import logocdt from '../images/logoCdt.png';
import logootv from '../images/logoOtv.jpg';
import logoesat from '../images/logoEsat.jpg';
import logottvl from '../images/logo-ttvl.png';
import logotv from '../images/logoTerritoiresVendomois.jpg';
import logold from '../images/logoLoirDecouvertes.jpg';
import logotoutourisme from '../images/logoToutourisme.png';

const Partenaires = () => {
    return (
        <div className="partenaires">
            
            <h2 className='LigneH2t' > - </h2>
            
            <h2 className='LigneH2c' >Nos Partenaires</h2>
            
            <div className="partenaires listePart">

            <img src={logoadn41} className='listePart logo-adn41' alt='logo Adn41' />

            <a href="https://trootourisme.jimdofree.com" target="_blank" rel="noreferrer">
                <img src={logocdt} className='listePart logo-cdt' alt='logo Coeur de Troo' />
            </a>

            <a href='https://www.esat-apf-jeanmuriel.fr/' target='_blank' rel="noreferrer">
                <img src={logoesat} className='logo-esat' alt='Esat de lunay' />
            </a>

            <a href='https://www.historius-montoire.fr/' target='_blank' rel="noreferrer">
                <img src={logohistorius} className='logo-historius' alt='logo HistOrius' />
            </a>

            <a href='https://loirdecouvertes.fr/' target='_blank' rel="noreferrer">
                <img src={logold} className='logo-ld' alt='Loir Découvertes' />
            </a>

            <a href='https://www.vendome-tourisme.fr/' target='_blank' rel="noreferrer">
                <img src={logootv} className='logo-otv' alt='logo Office Tourisme Vendome' />
            </a>

            <a href='https://www.territoiresvendomois.fr/' target='_blank' rel="noreferrer">
                <img src={logotv} className='logo-tv' alt='Territoires Vendomois' />
            </a>
            
            <a href='https://www.ttvl.fr/' target='_blank' rel="noreferrer">
                <img src={logottvl} className='logo-ttvl' alt='Train Touristique de la Vallée du Loir' />
            </a>

            <a href='https://toutourismefrance.jimdofree.com/' target='_blank' rel="noreferrer">
                <img src={logotoutourisme} className='logo-toutourisme' alt='Le Toutourisme' />
            </a>
            </div>

            <h2 className='LigneH2t' > - </h2>
            <h2 className='LigneH2t' > - </h2>
            <Footer />
            
        </div>
    );
};

export default Partenaires;