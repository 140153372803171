import React from 'react';
import '../../styles/Presentation.css';
import ImgGareTrooEt21 from '../../images/Detage1.jpg';
import ImgGareTrooEt22 from '../../images/Detage2.jpg';

const Detage = () => {
    return (
        <div className='Gare'>
        <div className='LigneTitre'>
            <h1 className='LigneTitre'> Deuxième Etage</h1>
        </div>
        
        <div className='GareLigne ColonPres'>
            <img className='ImagPres' src={ImgGareTrooEt21} alt="Gare de Troo" />
            <h2 className='GareH2'>Au deuxième et dernier étage une salle de trains miniatures
             qui ravit les petits et les grands.
            </h2>
            <img className='ImagPres' src={ImgGareTrooEt22} alt="Gare de Troo" />
        </div>
    </div>
    );
};

export default Detage;