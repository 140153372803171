import React from 'react';
import '../../styles/Presentation.css';
import ImgGareTroo from '../../images/Gare1.jpg';
import ImgGareTroo2 from '../../images/GareRue.jpg';

const Gare = () => {
    return (
         
        <div className='Gare' lang='fr'>
            <div className='LigneTitre'>
                <h1 className='LigneTitre'> La Gare</h1>
            </div>
            
            <div className='GareLigne ColonPres' lang='fr'>
                <img className='ImagPres ' src={ImgGareTroo} alt="Gare de Troo" />
                <h2 className='GareH2' lang='fr'>La gare de Trôo située sur le territoire de Saint Quentin lès Trôo 
                (commune rattachée à Montoire sur le Loir) est gérée par
                 les bénévoles de l’Amicale de la Gare de Trôo qui agissent en 
                 liaison avec les bénévoles de l’association Train Touristique
                  de la Vallée de Loir (TTVL).
                </h2>
                <img className='ImagPres' src={ImgGareTroo2} alt="Gare de Troo" />
            </div>
        </div>
    );
};

export default Gare;