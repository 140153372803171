import React from 'react';
import '../styles/Presentation.css';
import Navigation from '../components/Navigation';
import Benevoles from './Presentation/Benevoles';
import Detage from './Presentation/Detage';
import Gare from './Presentation/Gare';
import Horaires from './Presentation/Horaires';
import Petage from './Presentation/Petage';
import Rdc from './Presentation/Rdc';
import HistoriqueGare from './Presentation/HistoriqueGare';
import Footer from '../components/Footer';

const Presentation = () => {
    return (
        <div className='AppAgt'>
            <div className='Presentation'>
                <h1 className='PresentationH1'> Historique de la gare</h1>
                <div className='PresentationItems'>
                    <HistoriqueGare />
                </div>
                
                <h1 className='PresentationH1'> La Gare de Trôo Aujourd'hui</h1>
                <div className='PresentationItems'>
                    <Gare />
                    <Rdc />
                    <Petage />
                    <Detage />
                    {/* <Horaires /> */}
                    <Benevoles />
                </div>
                <Footer />
            </div>
        </div>
    );
};

export default Presentation;