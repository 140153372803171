import React from 'react';
import '../../styles/Presentation.css';
import ImgGareTrooRdc1 from '../../images/Rdc1.jpg';
import ImgGareTrooRdc2 from '../../images/Rdc2.jpg';

const Rdc = () => {
    return (
        <div className='Gare'>
            <div className='LigneTitre'>
                <h1 className='LigneTitre'> Le Rez de Chaussée</h1>
            </div>
            
            <div className='GareLigne ColonPres'>
                <img className='ImagPres' src={ImgGareTrooRdc1} alt="Gare de Troo" />
                <h2 className='GareH2'>Au rez-de-chaussée des photos anciennes des gares de la région,
                 une exposition d’outils préhistorique et de fossiles trouvées dans la région
                  ainsi qu’une boutique de souvenirs (cartes postales, magnets, livres sur la région, mugs, set de tables,…).
                </h2>
                <img className='ImagPres' src={ImgGareTrooRdc2} alt="Gare de Troo" />
            </div>
        </div>
    );
};

export default Rdc;