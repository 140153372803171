import React from 'react';
import '../../styles/Horaires.css';
import { useNavigate } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import EntetePageHoraires from '../../components/EntetePageHoraires';

import Footer from '../../components/Footer';

function Juin () {

    const navigate = useNavigate();

    return (
        <div className='AppAgt'>
        <div>
        <EntetePageHoraires />
        <h2 className='LigneH2t' > - </h2>
        <div >
        <table className='HoTbl' align='center'>
            <tbody>
                <tr>
                    <td className='HoTbllit'><Button className='BtnHor' onClick={() => navigate('/Avril')}>Avril</Button> </td>
                    <td className='HoTbllit'> </td>
                    <td className='HoTbllit'> </td>
                </tr>
                <tr className='HoTblli'>
                    <td className='HoTblli'><Button className='BtnHor' onClick={() => navigate('/Mai')}>Mai</Button> </td>
                    <td className='HoTbllit'> </td>
                    <td className='HoTbllit'> </td>                 
                </tr>
                <tr>
                    <td className='HoTbllit'><Button className='BtnHor' onClick={() => navigate('/Juin')}>Juin</Button> </td>
                    <td className='HoTbllit'> Date </td>
                    <td className='HoTbllit'> Ouverture </td>
                </tr>
                
                <tr>
                    <td className='HoTblli'>  </td>
                    <td className='HoTblli'> Sam 01/06 </td>
                    <td className='HoTblli'> Fermé </td>                 
                </tr>
                <tr>
                    <td className='HoTblli'>  </td>
                    <td className='HoTblli'> Dim 02/06 </td>
                    <td className='HoTblli'> 10h-12h </td>                 
                </tr>
                <tr className='HoTblli'>
                    <td className='HoTblli'> </td>
                    <td className='HoTblli'> Lun 03/06 </td>
                    <td className='HoTblli'> Fermé </td>                 
                </tr>
                <tr>
                    <td className='HoTblli'>  </td>
                    <td className='HoTblli'> Mar 04/06 </td>
                    <td className='HoTblli'> 10h-12h </td>                 
                </tr>
                <tr>
                    <td className='HoTblli'>  </td>
                    <td className='HoTblli'> Mer 05/06 </td>
                    <td className='HoTblli'> 14h30-17h </td>                 
                </tr>
                <tr>
                    <td className='HoTblli'>  </td>
                    <td className='HoTblli'> Jeu 06/06 </td>
                    <td className='HoTblli'> 10h-12h </td>                 
                </tr>
                <tr>
                    <td className='HoTblli'>  </td>
                    <td className='HoTblli'> Ven 07/06 </td>
                    <td className='HoTblli'> Fermé </td>                 
                </tr>
                <tr>
                    <td className='HoTblli'>  </td>
                    <td className='HoTblli'> Sam 08/06 </td>
                    <td className='HoTblli'> 14h30-17h </td>                 
                </tr>
                <tr>
                    <td className='HoTblli'>  </td>
                    <td className='HoTblli'> Dim 09/06 </td>
                    <td className='HoTblli'> Fermé </td>                 
                </tr>
                <tr className='HoTblli'>
                    <td className='HoTblli'> </td>
                    <td className='HoTblli'> Lun 10/06 </td>
                    <td className='HoTblli'> Fermé </td>                 
                </tr>
                <tr>
                    <td className='HoTblli'>  </td>
                    <td className='HoTblli'> Mar 11/06 </td>
                    <td className='HoTblli'> 10h-12h </td>                 
                </tr>
                <tr>
                    <td className='HoTblli'>  </td>
                    <td className='HoTblli'> Mer 12/06 </td>
                    <td className='HoTblli'> 14h30-17h </td>                 
                </tr>
                <tr>
                    <td className='HoTblli'>  </td>
                    <td className='HoTblli'> Jeu 13/06 </td>
                    <td className='HoTblli'> 10h-12h </td>                 
                </tr>
                <tr>
                    <td className='HoTblli'>  </td>
                    <td className='HoTblli'> Ven 14/06 </td>
                    <td className='HoTblli'> Fermé </td>                 
                </tr>
                <tr>
                    <td className='HoTblli'>  </td>
                    <td className='HoTblli'> Sam 15/06 </td>
                    <td className='HoTblli'> Fermé </td>                 
                </tr>
                <tr>
                    <td className='HoTblli'>  </td>
                    <td className='HoTblli'> Dim 16/06 </td>
                    <td className='HoTblli'> Fermé </td>                 
                </tr>
                <tr className='HoTblli'>
                    <td className='HoTblli'> </td>
                    <td className='HoTblli'> Lun 17/06 </td>
                    <td className='HoTblli'> Fermé </td>                 
                </tr>
                <tr>
                    <td className='HoTblli'>  </td>
                    <td className='HoTblli'> Mar 18/06 </td>
                    <td className='HoTblli'> Fermé </td>                 
                </tr>
                <tr>
                    <td className='HoTblli'>  </td>
                    <td className='HoTblli'> Mer 19/06 </td>
                    <td className='HoTblli'> 14h30-17h </td>                 
                </tr>
                <tr>
                    <td className='HoTblli'>  </td>
                    <td className='HoTblli'> Jeu 20/06 </td>
                    <td className='HoTblli'> Fermé </td>                 
                </tr>
                <tr>
                    <td className='HoTblli'>  </td>
                    <td className='HoTblli'> Ven 21/06 </td>
                    <td className='HoTblli'> Fermé </td>                 
                </tr>
                <tr>
                    <td className='HoTblli'>  </td>
                    <td className='HoTblli'> Sam 22/06 </td>
                    <td className='HoTblli'> Fermé </td>                 
                </tr>
                <tr>
                    <td className='HoTblli'>  </td>
                    <td className='HoTblli'> Dim 23/06 </td>
                    <td className='HoTblli'> Fermé </td>                 
                </tr>
                <tr className='HoTblli'>
                    <td className='HoTblli'> </td>
                    <td className='HoTblli'> Lun 24/06 </td>
                    <td className='HoTblli'> Fermé </td>                 
                </tr>
                <tr>
                    <td className='HoTblli'>  </td>
                    <td className='HoTblli'> Mar 25/06 </td>
                    <td className='HoTblli'> 10h-12h </td>                 
                </tr>
                <tr>
                    <td className='HoTblli'>  </td>
                    <td className='HoTblli'> Mer 26/06 </td>
                    <td className='HoTblli'> 14h30-17h </td>                 
                </tr>
                <tr>
                    <td className='HoTblli'>  </td>
                    <td className='HoTblli'> Jeu 27/06 </td>
                    <td className='HoTblli'> 10h-12h </td>                 
                </tr>
                <tr>
                    <td className='HoTblli'>  </td>
                    <td className='HoTblli'> Ven 28/06 </td>
                    <td className='HoTblli'> Fermé </td>                 
                </tr>
                <tr>
                    <td className='HoTblli'>  </td>
                    <td className='HoTblli'> Sam 29/06 </td>
                    <td className='HoTblli'> 14h30-17h </td>                 
                </tr>
                <tr>
                    <td className='HoTblli'>  </td>
                    <td className='HoTblli'> Dim 30/06 </td>
                    <td className='HoTblli'> Fermé </td>                 
                </tr>
                <tr>
                    <td className='HoTbllit'><Button className='BtnHor' onClick={() => navigate('/Juillet')}>Juillet</Button> </td>
                    <td className='HoTbllit'> </td>
                    <td className='HoTbllit'> </td>   
                </tr>
                <tr>
                    <td className='HoTbllit'><Button className='BtnHor' onClick={() => navigate('/Aout')}>Aout</Button> </td>
                    <td className='HoTbllit'> </td>
                    <td className='HoTbllit'> </td>
                </tr>
                <tr>
                    <td className='HoTbllit'><Button className='BtnHor' onClick={() => navigate('/Septembre')}>Septembre</Button> </td>
                    <td className='HoTbllit'> </td>
                    <td className='HoTbllit'> </td>
                </tr>
                <tr>
                    <td className='HoTbllit'><Button className='BtnHor' onClick={() => navigate('/Octobre')}>Octobre</Button> </td>
                    <td className='HoTbllit'> </td>
                    <td className='HoTbllit'> </td>
                </tr>
                <tr>
                    <td className='HoTbllit'><Button className='BtnHor' onClick={() => navigate('/Novembre')}>Novembre</Button> </td>
                    <td className='HoTbllit'> </td>
                    <td className='HoTbllit'> </td>
                </tr>
                
             </tbody>
            </table> 
            <h2 className='LigneH2t' > - </h2>  
            </div>    
            <Footer />
            
        </div>
    </div>
    );
};

export default Juin;