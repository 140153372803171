import React from 'react';
import '../../styles/Presentation.css';
import ImgH1 from '../../images/H1.jpg';
import ImgH2 from '../../images/H2.jpg';
import ImgH3 from '../../images/H3.jpg';
import ImgH4 from '../../images/H4.jpg';

const HistoriqueGare = () => {
    return (
         
        <div className='Gare' lang='fr'>
             <div className='LigneTitre'>
                <h1 className='LigneTitre'> Création de la ligne</h1>
            </div>           
            <div className='GareLigne ColonPres' lang='fr'>
                <img className='ImagPres' src={ImgH1} alt="Gare de Troo" />
                <h2 className="GareH2" lang="fr">L’idée d’une ligne Blois Pont de Braye date de 1843 au moins. La décision de créer une gare à Trôo fut prise le 7 Septembre 1878. En 1881, les travaux vont bon train. La ligne est inaugurée le 20 Novembre 1881.
                L’arrêt définitif du trafic voyageur sur la ligne sera décidé à partir du 1 Mars 1940. L’arrêt du trafic marchandises en gare de Trôo en Janvier 1989.
                
                </h2>
                <img className='ImagPres' src={ImgH2} alt="Gare de Troo" />
            </div>
            <div className='LigneTitre'>
                <h1 className='LigneTitre'> Création du TTVL (Train Touristique de la vallée du Loir)</h1>
            </div>
            <div className='GareLigne ColonPres' lang='fr'>
                <img className='GareLigne ColonPres ImagPres' src={ImgH3} alt="Gare de Troo" />
                <h2 className='GareLigne ColonPres GareH2' lang='fr'>L’idée du TTVL est lancée en 1986. Il est créé en Janvier 1987. La gare de Trôo est en piteux état. Les abords envahis par la végétation. De 1990 à 1992, les bénévoles vont dégager la voie, débroussailler, débarrasser, remplacer les vitres, restaurer l’intérieur de la gare (plâtres, peintures,..), remettre en état les guichets, aménager les étages et le musée, refaire le quai. Des entreprises seront sollicitées pour une partie des menuiseries ; le remplacement de l’escalier ; l’aménagement de toilettes. La ligne TTVL sera inaugurée le 27 Juin 1992. L’amicale de la gare de Trôo est créée en 1998.
                </h2>
                <img className='GareLigne ColonPres ImagPres' src={ImgH4} alt="Gare de Troo" />
            </div>
        </div>
    );
};

export default HistoriqueGare;