import React from 'react';
import '../../styles/Horaires.css';
import { useNavigate } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import EntetePageHoraires from '../../components/EntetePageHoraires';

import Footer from '../../components/Footer';

function Avril () {

    const navigate = useNavigate();

    return (
        <div className='AppAgt'>
        <div>
        <EntetePageHoraires />
        <h2 className='LigneH2t' > - </h2>
        <div >
        <table className='HoTbl' align='center'>
            <tbody>
                <tr className='HoTblli'>
                    <td className='HoTblli'><Button className='BtnHor' onClick={() => navigate('/Avril')}>Avril</Button> </td>
                    <td className='HoTbllit'> Date </td>
                    <td className='HoTbllit'> Ouverture </td>                 
                </tr>
                <tr className='HoTblli'>
                    <td className='HoTblli'> </td>
                    <td className='HoTblli'> Lun 01/04 </td>
                    <td className='HoTblli'> Fermé </td>                 
                </tr>
                <tr>
                    <td className='HoTblli'>  </td>
                    <td className='HoTblli'> Mar 02/04 </td>
                    <td className='HoTblli'> Fermé </td>                 
                </tr>
                <tr>
                    <td className='HoTblli'>  </td>
                    <td className='HoTblli'> Mer 03/04 </td>
                    <td className='HoTblli'> 14h30-17h </td>                 
                </tr>
                <tr>
                    <td className='HoTblli'>  </td>
                    <td className='HoTblli'> Jeu 04/04 </td>
                    <td className='HoTblli'> Fermé </td>                 
                </tr>
                <tr>
                    <td className='HoTblli'>  </td>
                    <td className='HoTblli'> Ven 05/04 </td>
                    <td className='HoTblli'> Fermé </td>                 
                </tr>
                <tr>
                    <td className='HoTblli'>  </td>
                    <td className='HoTblli'> Sam 06/04 </td>
                    <td className='HoTblli'> Fermé </td>                 
                </tr>
                <tr>
                    <td className='HoTblli'>  </td>
                    <td className='HoTblli'> Dim 07/04 </td>
                    <td className='HoTblli'> Fermé </td>                 
                </tr>
                <tr className='HoTblli'>
                    <td className='HoTblli'> </td>
                    <td className='HoTblli'> Lun 08/04 </td>
                    <td className='HoTblli'> Fermé </td>                 
                </tr>
                <tr>
                    <td className='HoTblli'>  </td>
                    <td className='HoTblli'> Mar 09/04 </td>
                    <td className='HoTblli'> Fermé </td>                 
                </tr>
                <tr>
                    <td className='HoTblli'>  </td>
                    <td className='HoTblli'> Mer 10/04 </td>
                    <td className='HoTblli'> 14h30-17h </td>                 
                </tr>
                <tr>
                    <td className='HoTblli'>  </td>
                    <td className='HoTblli'> Jeu 11/04 </td>
                    <td className='HoTblli'> Fermé </td>                 
                </tr>
                <tr>
                    <td className='HoTblli'>  </td>
                    <td className='HoTblli'> Ven 12/04 </td>
                    <td className='HoTblli'> Fermé </td>                 
                </tr>
                <tr>
                    <td className='HoTblli'>  </td>
                    <td className='HoTblli'> Sam 13/04 </td>
                    <td className='HoTblli'> Fermé </td>                 
                </tr>
                <tr>
                    <td className='HoTblli'>  </td>
                    <td className='HoTblli'> Dim 14/04 </td>
                    <td className='HoTblli'> Fermé </td>                 
                </tr>
                <tr className='HoTblli'>
                    <td className='HoTblli'> </td>
                    <td className='HoTblli'> Lun 15/04 </td>
                    <td className='HoTblli'> Fermé </td>                 
                </tr>
                <tr>
                    <td className='HoTblli'>  </td>
                    <td className='HoTblli'> Mar 16/04 </td>
                    <td className='HoTblli'> Fermé </td>                 
                </tr>
                <tr>
                    <td className='HoTblli'>  </td>
                    <td className='HoTblli'> Mer 17/04 </td>
                    <td className='HoTblli'> 14h30-17h </td>                 
                </tr>
                <tr>
                    <td className='HoTblli'>  </td>
                    <td className='HoTblli'> Jeu 18/04 </td>
                    <td className='HoTblli'> Fermé </td>                 
                </tr>
                <tr>
                    <td className='HoTblli'>  </td>
                    <td className='HoTblli'> Ven 19/04 </td>
                    <td className='HoTblli'> Fermé </td>                 
                </tr>
                <tr>
                    <td className='HoTblli'>  </td>
                    <td className='HoTblli'> Sam 20/04 </td>
                    <td className='HoTblli'> Fermé </td>                 
                </tr>
                <tr>
                    <td className='HoTblli'>  </td>
                    <td className='HoTblli'> Dim 21/04 </td>
                    <td className='HoTblli'> Fermé </td>                 
                </tr>
                <tr className='HoTblli'>
                    <td className='HoTblli'> </td>
                    <td className='HoTblli'> Lun 22/04 </td>
                    <td className='HoTblli'> Fermé </td>                 
                </tr>
                <tr>
                    <td className='HoTblli'>  </td>
                    <td className='HoTblli'> Mar 23/04 </td>
                    <td className='HoTblli'> Fermé </td>                 
                </tr>
                <tr>
                    <td className='HoTblli'>  </td>
                    <td className='HoTblli'> Mer 24/04 </td>
                    <td className='HoTblli'> 14h30-17h </td>                 
                </tr>
                <tr>
                    <td className='HoTblli'>  </td>
                    <td className='HoTblli'> Jeu 25/04 </td>
                    <td className='HoTblli'> Fermé </td>                 
                </tr>
                <tr>
                    <td className='HoTblli'>  </td>
                    <td className='HoTblli'> Ven 26/04 </td>
                    <td className='HoTblli'> Fermé </td>                 
                </tr>
                <tr>
                    <td className='HoTblli'>  </td>
                    <td className='HoTblli'> Sam 27/04 </td>
                    <td className='HoTblli'> 10h-12h </td>                 
                </tr>
                <tr>
                    <td className='HoTblli'>  </td>
                    <td className='HoTblli'> Dim 28/04 </td>
                    <td className='HoTblli'> 14h30-17h </td>                 
                </tr>
                <tr className='HoTblli'>
                    <td className='HoTblli'> </td>
                    <td className='HoTblli'> Lun 29/04 </td>
                    <td className='HoTblli'> Fermé </td>                 
                </tr>
                <tr>
                    <td className='HoTblli'>  </td>
                    <td className='HoTblli'> Mar 30/04 </td>
                    <td className='HoTblli'> Fermé </td>                 
                </tr>
                
                <tr>
                    <td className='HoTbllit'><Button className='BtnHor' onClick={() => navigate('/Mai')}>Mai</Button> </td>
                    <td className='HoTbllit'> </td>
                    <td className='HoTbllit'> </td>
                </tr>
                <tr>
                    <td className='HoTbllit'><Button className='BtnHor' onClick={() => navigate('/Avril')}>Juin</Button> </td>
                    <td className='HoTbllit'> </td>
                    <td className='HoTbllit'> </td>
                </tr>
                <tr>
                    <td className='HoTbllit'><Button className='BtnHor' onClick={() => navigate('/Avril')}>Juillet</Button> </td>
                    <td className='HoTbllit'> </td>
                    <td className='HoTbllit'> </td>   
                </tr>
                <tr>
                    <td className='HoTbllit'><Button className='BtnHor' onClick={() => navigate('/Avril')}>Aout</Button> </td>
                    <td className='HoTbllit'> </td>
                    <td className='HoTbllit'> </td>
                </tr>
                <tr>
                    <td className='HoTbllit'><Button className='BtnHor' onClick={() => navigate('/Avril')}>Septembre</Button> </td>
                    <td className='HoTbllit'> </td>
                    <td className='HoTbllit'> </td>
                </tr>
                <tr>
                    <td className='HoTbllit'><Button className='BtnHor' onClick={() => navigate('/Avril')}>Octobre</Button> </td>
                    <td className='HoTbllit'> </td>
                    <td className='HoTbllit'> </td>
                </tr>
                <tr>
                    <td className='HoTbllit'><Button className='BtnHor' onClick={() => navigate('/Avril')}>Novembre</Button> </td>
                    <td className='HoTbllit'> </td>
                    <td className='HoTbllit'> </td>
                </tr>
             </tbody>
            </table> 
            <h2 className='LigneH2t' > - </h2>  
            </div>    
            <Footer />
            
        </div>
    </div>
    );
};

export default Avril;