import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../../styles/Boutique.css';
import Livres1 from '../../images/Livres1.jpg';
import Livres2 from '../../images/Livres2.jpg';

import ImgAccueilBoutique from '../../components/ImgAccueilBoutique';
import Footer from '../../components/Footer';

const LivresDetail = () => {
    const navigate= useNavigate()
    return (
        <div className='BtqDetail'>
            <ImgAccueilBoutique />
            <h1 className='BtqDetail H1BtqDetail'> </h1>
            <h1 className='BtqDetail H2espBtqDetail'> </h1>
            <h2 className='BtqDetail H2BtqDetail'> Nos Livres</h2>
            <h1 className='BtqDetail H2espBtqDetail'> </h1>
            
            <div className='BtqDetail ColonBtqDetail'>
                <h3 className='BtqDetail ColonBtqDetail H4BtqDetail'>Voici un aperçu des livres que vous trouverez dans notre boutique</h3>
            </div>

            <div className='BtqDetail ColonBtqDetail'>
                <h3 className='BtqDetail ColonBtqDetail H3BtqDetail'>- Livres sur l'histoire de la région</h3>
                <h3 className='BtqDetail ColonBtqDetail H3BtqDetail'>- Livres touristiques</h3>
                <h3 className='BtqDetail ColonBtqDetail H3BtqDetail'>- Livres sur les autorails et draisines</h3>
            </div>
            
            <div className='BtqDetail ColonBtqDetail'>
                <img className='BtqDetail ColonBtqDetail ImagBtqDetailLivres' src={Livres1} alt="Cartes de la région" />
                <img className='BtqDetail ColonBtqDetail ImagBtqDetailLivres' src={Livres2} alt="Cartes de la région" />
            </div>
            <Footer />
        </div>
    );
};

export default LivresDetail;