import React from 'react';
import '../../styles/BoutiqueDetail.css';
import SetEsat from '../../images/SetEsat.jpg';
import SetJM1 from '../../images/SetJM1.jpg';
import SetJM2 from '../../images/SetJM2.jpg';

import ImgAccueilBoutique from '../../components/ImgAccueilBoutique';
import Footer from '../../components/Footer';

const SetsDetail = () => {
    
    return (
        <div className='BtqDetail'>
            <ImgAccueilBoutique />
            <h1 className='BtqDetail H1BtqDetail'> </h1>
            <h1 className='BtqDetail H2espBtqDetail'> </h1>
            <h2 className='BtqDetail H2BtqDetail'> Sets de table</h2>
            <h1 className='BtqDetail H2espBtqDetail'> </h1>
            
            <div className='BtqDetail ColonBtqDetail'>
                <div className='BtqDetail ColonBtqDetail'>
                    <img className='BtqDetail ColonBtqDetail ImagBtqDetail3' src={SetEsat} alt="Gare de Troo" />
                    <h3 className='Mag'> </h3>
                    <h3 className='H4BtqDetail'>Set de table recto/verso créé en collaboration avec
                    <a className='LnkBtq' href="https://www.esat-apf-jeanmuriel.fr/" target="_blank"> L'Esat Jean Muriel de Lunay</a>
                    </h3>
                </div>        
            </div>
        
            
                 
            <Footer />
            
        </div>
    );
};

export default SetsDetail;