import React from 'react';
import '../../styles/Presentation.css';

const Horaires = () => {
    return (
        <div className='Gare'>
        <div className='LigneTitre'>
            <h1 className='LigneTitre'> Horaires d'ouverture</h1>
        </div>
        
        <div className='GareLigne ColonPres'>
            <h2 className='GareLigne ColonPres GareH2b'> La gare est ouverte :</h2>
            <h2 className='GareLigne ColonPres GareH2b'> -	Tous les Mercredis Aprés midi de 14h30 à 17H00. C'est le jour des ateliers de modélisme animés par les modélistes de l’Amicale 
            (entretien du réseau, création de nouveau modules, …)</h2>
            <h2 className='GareLigne ColonPres GareH2b'> -	Tous les Samedis Aprés midi de 14h30 à 17H00 du 3 Juin au 16 Septembre 2023.</h2>
            <h2 className='GareLigne ColonPres GareH2b'> -	Lors des arrêts de l’autorail X2400 des années cinquante à la gare de Trôo. 
            </h2>
            <h2 className='GareLigne ColonPres GareH2b'> -	Autres jours nous contacter pour prendre rendez-vous
            </h2>
            <h2 className='GareLigne ColonPres GareH2b'> -	Les visiteurs peuvent faire une pause à la buvette,
             visiter la gare, acheter des souvenirs à la boutique et profiter des tables de pique-nique à l'extérieur.
            </h2>
            
        </div>
    </div>
    );
};

export default Horaires;