import React , { useState }from 'react';
import { NavLink } from 'react-router-dom';
import '../styles/Navigation.css';
import Logo from '../images/logo-GareTroo.jpg';

const Navigation = () => {
    const [menuOpen, setMenuOpen] = useState(false);

    const handleMenuItemClick = () => {
      setMenuOpen(false);
    };

    return (
                   
        <div className="navigation">
                <a href="./Accueil"> <img src={Logo} className="imgLogo" alt="Gare de Troo" /></a>
                <input id="menu-toggle" type="checkbox" checked={menuOpen} onChange={() => setMenuOpen(!menuOpen)}  />
                <label className='menu-button-container' htmlFor="menu-toggle">
                    <div style={{ fontWeight: 'bold', color: 'white',marginRight : '95px'}}>Menu</div>
                    <div className='menu-button'></div>
                </label>


                {/* <a href="./Accueil"> <img src={Logo} className="imgLogo" alt="Gare de Troo" /></a> */}
            <ul className="menu">
                <li>
                    <NavLink to="/Accueil" className = "menuLinkButton" onClick={handleMenuItemClick}>Accueil</NavLink> 
                </li>
                <li>
                    <NavLink to="/Presentation" className = "menuLinkButton" onClick={handleMenuItemClick}>Présentation</NavLink> 
                </li>
                {/* <li>
                    <NavLink to="/Actualites" className = "menuLinkButton" onClick={handleMenuItemClick} >Actualités</NavLink> 
                </li> */}
                <li>
                    <NavLink to="/Boutique" className = "menuLinkButton" onClick={handleMenuItemClick}>Boutique</NavLink> 
                </li>
                <li>
                    <NavLink to="/Contact" className = "menuLinkButton" onClick={handleMenuItemClick}>Contact</NavLink> 
                </li>
                <li>
                    <NavLink to="/Horaires" className = "menuLinkButton" onClick={handleMenuItemClick}>Horaires</NavLink> 
                </li>
                <li>
                    <NavLink to="/Partenaires" className = "menuLinkButton" onClick={handleMenuItemClick}>Partenaires</NavLink> 
                </li>
            </ul>
        </div>

    );
};

export default Navigation;