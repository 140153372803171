import './App.css';
import { BrowserRouter,Routes,Route } from 'react-router-dom';
import Accueil from './pages/Accueil';
import Presentation from './pages/Presentation';
import Actualites from './pages/Actualites';
import Boutique from './pages/Boutique';
import Contact from './pages/Contact';
import Horaires from './pages/Horaires';
import Partenaires from './pages/Partenaires';
// import Compteur from './pages/Compteur';
import CartesDetail from './pages/Boutique/CartesDetail';
import DiversDetail from './pages/Boutique/DiversDetail';
import LivresDetail from './pages/Boutique/LivresDetail';
import MugsDetail from './pages/Boutique/MugsDetail';
import SetsDetail from './pages/Boutique/SetsDetail';
import MagnetsDetail from './pages/Boutique/MagnetsDetail';
import TeeshirtsDetail from './pages/Boutique/TeeshirtsDetail';
import Nouveautes from './pages/Boutique/Nouveautes';
import Avril from './pages/Horaires/Avril';
import Mai from './pages/Horaires/Mai';
import Juin from './pages/Horaires/Juin';
import Juillet from './pages/Horaires/Juillet';
import Aout from './pages/Horaires/Aout';
import Septembre from './pages/Horaires/Septembre';
import Octobre from './pages/Horaires/Octobre';
import Novembre from './pages/Horaires/Novembre';

import Navigation from './components/Navigation';

function App() {
  return (
    <div className="App" lang="fr">
        
        <BrowserRouter>
        <Navigation/>
            <Routes>
                <Route path="/" element={<Accueil />} />
                <Route path="/Accueil" element={<Accueil />} />
                <Route path="/presentation" element={<Presentation />} />
                <Route path="/actualites" element={<Actualites />} />
                <Route path="/boutique" element={<Boutique />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/horaires" element={<Horaires />} />
                <Route path="/Partenaires" element={<Partenaires />} />
                {/* <Route path="/planning" element={<Planning />} />
                <Route path="/connexion" element={<Connexion />} /> */}
                {/* <Route path="/compteur" element={<Compteur />} /> */}
                <Route path="/CartesDetail" element={<CartesDetail />} />
                <Route path="/DiversDetail" element={<DiversDetail />} />
                <Route path="/LivresDetail" element={<LivresDetail />} />
                <Route path="/MugsDetail" element={<MugsDetail />} />
                <Route path="/SetsDetail" element={<SetsDetail />} />
                <Route path="/MagnetsDetail" element={<MagnetsDetail />} />
                <Route path="/TeeshirtsDetail" element={<TeeshirtsDetail />} />
                <Route path="/Nouveautes" element={<Nouveautes />} />
                {/* <Route path="/ZoomActualites" element={<ZoomActualites />} />  */}
                <Route path="/Avril" element={<Avril />} />
                <Route path="/Mai" element={<Mai />} />
                <Route path="/Juin" element={<Juin />} />
                <Route path="/Juillet" element={<Juillet />} />
                <Route path="/Aout" element={<Aout />} />
                <Route path="/Septembre" element={<Septembre />} />
                <Route path="/Octobre" element={<Octobre />} />
                <Route path="/Novembre" element={<Novembre />} />
                <Route path="*" element={<Accueil />} />
            </Routes>
        </BrowserRouter>
    </div>
  );
}

export default App;